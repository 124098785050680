import React, { useState } from 'react';
import { Box, Button, FormControl, FormLabel, Input, VStack, Text, Image, InputGroup, InputLeftElement, useToast } from '@chakra-ui/react';
import { FaUser, FaKey } from 'react-icons/fa'; // Importando ícones
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setUser }) => {
  const [cim, setCim] = useState('');
  const [cpf, setCpf] = useState('');
  const [error, setError] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const handleCimChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Permitir apenas números
    setCim(value);
  };

  const handleCpfChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remover não numéricos
    if (value.length <= 11) {
      const formattedCpf = value
        .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona o primeiro ponto
        .replace(/(\d{3})(\d)/, '$1.$2') // Adiciona o segundo ponto
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Adiciona o hífen
      setCpf(formattedCpf);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://igmportariaremota.com.br:3838/Login', { cim, cpf });

      if (response.data.result[0].length === 0) {
        setError('CIM ou CPF incorreto');
        toast({
          title: 'Erro no login',
          description: 'CIM ou CPF incorreto. Tente novamente.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        const userData = response.data.result[0][0];
        sessionStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);

        navigate('/'); // Navegar para a página inicial
      }
    } catch (error) {
      setError('Erro ao conectar com o servidor. Tente novamente mais tarde.');
      toast({
        title: 'Erro no servidor',
        description: 'Não foi possível conectar ao servidor.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={4} bg="hsl(192.86, 63.64%, 82.75%)" minHeight="100vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Image src="/icones/logo.png" alt="Logo" boxSize="150px" objectFit="contain" mb={6} />
      <Image src="fundo.jpeg" alt="Logo" boxSize="100%" objectFit="contain" mb={6} />

      <Box maxW="md" mx="auto" p={6} borderWidth={1} borderRadius="md" bg="hsl(192.86, 63.64%, 82.75%)">
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl id="cim" isRequired>
          
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaUser color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  value={cim}
                  onChange={handleCimChange}
                  placeholder="Digite seu CIM"
                  maxLength="6"
                />
              </InputGroup>
            </FormControl>

            <FormControl id="cpf" isRequired>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <FaKey color="gray.300" />
                </InputLeftElement>
                <Input
                  type="text"
                  value={cpf}
                  onChange={handleCpfChange}
                  placeholder="Digite seu CPF"
                />
              </InputGroup>
            </FormControl>

            {error && <Text color="red.500">{error}</Text>}

            <Button type="submit" colorScheme="blue" size="lg" width="full">
              ENTRAR
            </Button>
          </VStack>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
