import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Text,
  Center,
  IconButton,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  useToast,
  Flex,
  SimpleGrid,
  Divider,
  Link
} from '@chakra-ui/react';
import { FaImage, FaArrowLeft, FaDownload } from 'react-icons/fa';
import axios from 'axios';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const MemoriasFotograficasPage = () => {
  const [galerias, setGalerias] = useState({});
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [photos, setPhotos] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://igmportariaremota.com.br:3838/getGaleriaInstiuticao?id_insituicao=1')
      .then(response => {
        const galerias = response.data.result[0];
        // Organiza as galerias por ano
        const groupedByYear = galerias.reduce((acc, galeria) => {
          const year = new Date(galeria.data).getFullYear();
          if (!acc[year]) {
            acc[year] = [];
          }
          acc[year].push(galeria);
          return acc;
        }, {});
        setGalerias(groupedByYear);
      })
      .catch(error => {
        console.error('Erro ao buscar galerias:', error);
        toast({
          title: "Erro",
          description: "Não foi possível carregar as galerias.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }, []);

  useEffect(() => {
    const fetchFotos = async () => {
      if (selectedGallery && isGalleryOpen) {
        try {
          const response = await fetch(`https://igmportariaremota.com.br:3838/getFotosGaleria?id_galeria=${selectedGallery.id_galeria}`);
          const data = await response.json();
          setPhotos(data.fotos);
        } catch (error) {
          console.error('Erro ao buscar fotos:', error);
        }
      }
    };

    fetchFotos();
  }, [selectedGallery, isGalleryOpen]);

  const handleHomeClick = () => {
    navigate('/');
  };

  const handleYearClick = (year) => {
    setSelectedYear(year);
    setSelectedGallery(null);
    setIsGalleryOpen(false);
  };

  const handleGalleryClick = (gallery) => {
    setSelectedGallery(gallery);
    onModalOpen();
  };

  const handlePhotoClick = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleViewGallery = () => {
    onModalClose();
    setIsGalleryOpen(true);
  };

  const handleDownload = () => {
    const downloadUrl = `https://igmportariaremota.com.br:3838/Galerias/${selectedGallery.id_galeria}/${photos[photoIndex]}`;
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = photos[photoIndex];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Box
      bg="hsl(192.86, 63.64%, 82.75%)"
      p={4}
      borderWidth={1}
      borderRadius="md"
      boxShadow="md"
      minHeight="100vh"
      position="relative"
    >
      <Box
        position="absolute"
        top={4}
        left={4}
        zIndex={10}
      >
       <IconButton
  icon={<FaArrowLeft />}
  aria-label="Go Back"
  onClick={handleHomeClick}
  size="lg"
  colorScheme="blackAlpha" // Ajusta para uma variação de preto
  variant="solid"
  bg="black" // Fundo preto
  color="white" // Ícone branco para contraste
  borderRadius="full"
  boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
  _hover={{ bg: "gray.800" }} // Cor mais escura no hover
/>

      </Box>
      <Center mb={4}>
        <Box textAlign="center" mb={4}>
          <Image
            src="/icones/logo.png"
            alt="Logo"
            boxSize="128px"
            objectFit="contain"
            m="auto"
          />
        </Box>
      </Center>

      <Container>
        {/* Links para anos */}
        {selectedYear === null ? (
          <Box mb={6}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>Anos</Text>
            <SimpleGrid columns={[1, 2, 3]} spacing={4}>
              {Object.keys(galerias).map(year => (
                <Box
                  key={year}
                  p={4}
                  borderRadius="md"
                  bg="hsl(192.86, 63.64%, 72%)"
                  boxShadow="lg"
                  cursor="pointer"
                  onClick={() => handleYearClick(year)}
                  className="year-card"
                >
                  <Text fontSize="xl" fontWeight="bold">{year}</Text>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        ) : (
          <Box mb={6}>
            <IconButton
              icon={<FaArrowLeft />}
              aria-label="Voltar"
              onClick={() => setSelectedYear(null)}
              mb={4}
              colorScheme="teal"
              variant="outline"
              borderRadius="full"
              boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
              _hover={{ bg: "teal.600", color: "white" }}
            />
            <Text fontSize="2xl" fontWeight="bold" mb={4}>{selectedYear}</Text>
            <SimpleGrid columns={[1, 2, 3]} spacing={4}>
              {galerias[selectedYear].map(gallery => (
                <Box
                  key={gallery.id_galeria}
                  p={4}
                  borderRadius="md"
                  bg="hsl(192.86, 63.64%, 72%)"
                  boxShadow="lg"
                  cursor="pointer"
                  onClick={() => handleGalleryClick(gallery)}
                  className="gallery-card"
                >
                  <Flex align="center" mb={4}>
                    <IconButton
                      icon={<FaImage />}
                      aria-label={gallery.titulo}
                      size="lg"
                      colorScheme="teal"
                      variant="solid"
                      borderRadius="full"
                      boxShadow="0 4px 8px rgba(0, 0, 0, 0.2)"
                      _hover={{ bg: "teal.600" }}
                    />
                    <Box ml={4}>
                      <Text fontSize="xl" fontWeight="bold">{gallery.titulo}</Text>
                      <Text fontSize="lg" color="gray.600">{formatDate(gallery.data)}</Text>
                    </Box>
                  </Flex>
                </Box>
              ))}
            </SimpleGrid>
          </Box>
        )}

        {selectedGallery && (
          <Modal isOpen={isModalOpen} onClose={onModalClose} size="lg">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>{selectedGallery.titulo}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Center>
                  <Button variant="solid" colorScheme="teal" onClick={handleViewGallery}>
                    Ver Galeria
                  </Button>
                </Center>
                <Text mb={4}>{selectedGallery.descricao}</Text>
              </ModalBody>
              <ModalFooter />
            </ModalContent>
          </Modal>
        )}

        {isGalleryOpen && selectedGallery && (
          <Lightbox
            mainSrc={`https://igmportariaremota.com.br:3838/Galerias/${selectedGallery.id_galeria}/${photos[photoIndex]}`}
            nextSrc={`https://igmportariaremota.com.br:3838/Galerias/${selectedGallery.id_galeria}/${photos[(photoIndex + 1) % photos.length]}`}
            prevSrc={`https://igmportariaremota.com.br:3838/Galerias/${selectedGallery.id_galeria}/${photos[(photoIndex + photos.length - 1) % photos.length]}`}
            onCloseRequest={() => {
              setIsOpen(false);
              setIsGalleryOpen(false);
            }}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + photos.length - 1) % photos.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % photos.length)}
            imageCaption={
              <Box>
                <Flex align="center" justify="space-between" mb={2}>
                  <Button onClick={handleDownload} leftIcon={<FaDownload />} colorScheme="teal">
                    Download
                  </Button>
                </Flex>
                <Box
                  overflowX="auto"
                  whiteSpace="nowrap"
                  bg="white"
                  borderRadius="md"
                  boxShadow="sm"
                  py={2}
                >
                  <Flex>
                    {photos.map((photo, index) => (
                      <Box
                        key={index}
                        display="inline-block"
                        mr={2}
                        borderRadius="md"
                        cursor="pointer"
                        border={index === photoIndex ? "2px solid teal" : "none"}
                        _hover={{ borderColor: "teal" }}
                        onClick={() => setPhotoIndex(index)}
                      >
                        <Image
                          src={`https://igmportariaremota.com.br:3838/Galerias/${selectedGallery.id_galeria}/${photo}`}
                          boxSize="100px"
                          objectFit="cover"
                          borderRadius="md"
                        />
                      </Box>
                    ))}
                  </Flex>
                </Box>
              </Box>
            }
          />
        )}
      </Container>
    </Box>
  );
};

export default MemoriasFotograficasPage;
